.object_wrapper {
    margin-bottom: 30px;
}

.object {
    display: block;
    background: #FFF;
    text-decoration: none;
    color: $color1;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    text-decoration: none;
    
    &:hover {
        text-decoration: none;
    }

    figure {
        position: relative;
        margin: 0px;

        img {
            display: block;
            width: 100%;
        }

        figcaption {
            position: absolute;
            right: 0px;
            bottom: 0px;
            background: $color1;
            color: #FFF;
            padding: 0 15px;
            line-height: 50px;
            font-family: $font2;
            font-weight: 700;
            font-size: 16px;
            border-radius: 2px;
        }
    }

    .text {
        padding: 15px 15px 0 15px;
        height: 110px;

        h2, h3 {
            font-size: 16px;
            font-family: $font2;
            margin: 0;
            color: #5b5b5b;
        }

        h2 {
            font-size: 18px !important;
            margin-bottom: 7px;
        }
    }
}